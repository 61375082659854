* {
    box-sizing: border-box;
  }
  
.blog-page {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    margin-top: 0;
} 

.blog-container{
    display: flex;
    width: 100%;
}

.blog-left-container {
  flex: 1;
  padding: 1rem 4rem; 
}

.blog-left-container h1{
    font-family:  'Georgia', sans-serif;
    font-size: 3rem;
    text-align: left;
  }

.blog-left-container p{
    text-align: left;
}

.blog-right-container{
  flex: 1;
  padding: 1rem 4rem; 
}

.blog-right-container {
    justify-content: center;
    display: flex;
  }

.blog-image-container {
    background-size: contain;
    background-position: center center;
    background-image: url(../../images/woman.jpeg);
    width: 100%;
    background-repeat: no-repeat;
}

.blog-image-container.him-route{
  background-image: url(../../images/man.webp);
}

.blog-links {
  padding-left: 2rem;
}

.blog-links ul {
    display: flex;
    flex-direction: row;
} 

.blog-links li {
    list-style: none;
}

.blog-links button {
  padding: 0.8rem 1.4rem;
  color: #fff;
  border-radius: 0.5rem;
  background:linear-gradient(to bottom, #949a9c 5%, #DA70D6 100%);
  background-color:#6c6262;
  cursor:pointer;
  text-decoration:none;
  text-shadow:0px 1px 0px #393c3f;
  font-weight:bold;
  font: inherit;
  cursor: pointer;
  margin-right: 1rem;
  }

  .business-class button{
    background:linear-gradient(to bottom, #949a9c 5%, #f45f2e 100%);
    /* background-color:#626a6c; */
    text-shadow:0px 1px 0px #f45f2e;
  }

  .blog-links button.him-route {
    background: linear-gradient(to bottom, #949a9c 5%, #007bff 100%);
    background-color: #0056b3;
  }

  .blog-links button.active {
    color: #6e5757;
    position:relative;
    top:1px;
    background:linear-gradient(to bottom, #DA70D6 5%, #949a9c 100%);
  }

  .business-class button.active {
    color: #5d3e3e;
    background:linear-gradient(to bottom, #f45f2e 5%, #949a9c 100%);
  }

  .blog-links button.him-route.active {
    color: #465464;
    background:linear-gradient(to bottom, #007bff 5%, #949a9c 100%);
  }

  .blog-content{
    margin: 0;
  }

  .blog-left-container ul li{
    margin: 0.5rem 0;
  }

  .blog-left-container ol li{
    margin: 0.5rem 0;
  }

  