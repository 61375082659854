.newsletter_subscribe {
    /* margin-top: 20px; */
    padding: 0.5rem 10rem;
    text-align: center;
    align-items: center;
    justify-content: center
  }
  
  .newsletter_subscribe p {
    /* font-weight: bold; */
    font-size: 1.5rem;
    font-family:  'Georgia', sans-serif;
  }
  
  .newsletter_subscribe form {
    display: block;
    padding-bottom: 4rem;
  }
  
  .newsletter_subscribe input[type="submit"],
  .newsletter_subscribe input[type="email"]  {
    padding: 0.8rem 1.4rem;
    border-radius: 0;
  }
  
  .newsletter_subscribe input[type="email"] {
    border: 1px solid #ccc;
    width: 60%;
  }
  
  .newsletter_subscribe input[type="submit"] {
    background-color: #949a9c;
    color: white;
    cursor: pointer;
    width: 14%;
  }
  
  .newsletter_subscribe input[type="submit"]:hover {
    background-color: #686f6f;
  }
  
  
  
  