.service-home-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-service-home-page,
  .right-service-home-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .left-service-home-page{
    margin: 0 4rem
  }
  
  
img {
    width: 90%;
  }
  

p, h2{
    margin: 0.5rem 0;
}

/* h1{
    text-align: left;
} */

.buttons{
    position: relative;
    top: 10rem;
}

.button{
    margin-left: 4rem !important;
    font-weight: bold !important;
}