.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:5rem;
  width: 100%;
  /* position: fixed; */
  top: 0;
  z-index: 9999;
  padding: 1.5rem 2rem;
  background-color: #9e4307;
}

.logo{
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #fff;
}

.sub-title{
  margin-top: 0;
}

.main-title{
  text-align: center;
  margin-bottom: 0.5rem;
}


.menu {
  display: flex;
  list-style: none;
  margin: 0;
}

.menu li {
  margin: 0 0.3rem;
}

.menu a {
  text-decoration: none;
  color: #000;
}

.menu li a {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  font-weight: 600;
}

/* .navigation button {
  padding: 0.8rem 1.4rem;
  color: #fff;
  border-radius: 0.5rem;
	background:linear-gradient(to bottom, #949a9c 5%, #686f6f 100%);
	background-color:#626a6c;
  cursor:pointer;
  text-decoration:none;
	text-shadow:0px 1px 0px #393c3f;
  font-weight:bold;
  margin-right: 4rem;
} */


 .menu li a:hover {
  background-color: rgba(43, 48, 43, 0); 
  color: #060606;
  box-shadow: 0.1875rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

.menu li a.active {
  background-color: rgba(53, 60, 53, 0.454); 
  color: #fff;
  font-weight: bold;
  border-radius: 0.5rem;
}