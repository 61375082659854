.contact-page {
    min-height: 100vh;
    margin-top: 10rem;
} 

.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
} 


.contact-left-container,
.contact-right-container {
  flex: 1;
  padding: 1rem; 
}

.contact-left-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_image_container {
    background-size: cover;
    background-position: center center;
    background-image: url(../../images/image_one.png);
    margin-top: 4rem;
    padding: 10rem;
    width: 30rem;
    height: 20rem;
}

  .contact-form-container {
    max-width: 31.25rem;
    margin: 0 auto;
    padding: 0 1.25rem;
  }

  .contact-form-container h3 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    text-align: left;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .contact-form-container p {
    margin: 1rem 0;
    text-align: left;
  }
  
  .contact-form-container form {
    display: flex;
    flex-direction: column;
    
  }
  
.contact-form-container input,
.contact-form-container textarea {
    width: 100%;
    padding: 1rem 0.5rem;
    margin-bottom: 2rem;
    border: 0.0625rem solid #cccccc;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-family:  'Georgia', sans-serif;
    letter-spacing: 0.0625rem;
    background-color: #84909043;
}
  
.contact-form-container input:focus,
.contact-form-container textarea:focus {
    outline: none;
    box-shadow: 0 0 0.3rem #292d2e;
    font-family:  'Georgia', sans-serif;
}

.contact-form-container input::placeholder,
.contact-form-container textarea::placeholder{
  color: #000;
}
  
.contact-form-container button {
    padding: 1rem 1.5rem;
    width: 100%;
    background-color: #4751515b;
    /* color: #000; */
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    align-self: center;
    font-family:  'Georgia', sans-serif;
    font-size: 1rem;
  }
  
.contact-form-container button:hover {
    background-color: #4d504f;
}
  
