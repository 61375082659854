.home-page {
  background-image: url('../../images/main.JPG');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.body{
  display: flex;
  top: 40rem;
  position: relative;
  width: 100vw;
  justify-content: flex-start;
}

.input{
  background-color: aliceblue;
  width: 15rem;
  margin-left: 2rem;
  font-weight: bold;
}

.brand-list-label{
  font-weight: bold !important;
}