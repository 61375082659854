.container{
    display: grid;
    grid-template-columns: auto auto;
    padding: 1rem 2rem;
    gap: 0.5rem;
}

.image-item {
    margin: 1rem;
    background-color: #84909043;
    border-radius: 1rem;
    max-width: 100%;
    align-self: center; 
    background-size: cover;
    height:300px;
    position: relative;
}

.item-content{
    position: absolute;
    bottom: 0;
    padding: 0 2rem;
    padding-bottom: 3rem;
    color: #fff;
}