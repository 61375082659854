

.page-counter {
    text-align: center;
    margin-top: 1rem;
  }
  
  .pagination-buttons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

  }
  
  .pagination-buttons button {
    margin: 0 0.2rem;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    border: none;
    background-color: #686f6f ;
    color: #fff;
    border-radius: 3px;
  }

  .pagination-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .grid-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px; 
    padding: 2rem 4rem;
  }
  
  .grid-item {
    border: 1px solid #ccc;
    display: flex; 
    background-color: #84909043;
  }

.grid-item:nth-child(n+3):nth-child(-n+6) {
  grid-column-start: auto;
  grid-column-end: auto;
  grid-row-start: auto;
  grid-row-end: auto;
  flex-direction: column;
}

.grid-item:nth-child(n+1):nth-child(-n+2) {
  grid-column-start: span 2; 
  grid-row-start: span 4;
  flex-direction: row-reverse;
}



.item-image {
  margin: 1rem;
  background-color: #84909043;
  border-radius: 1rem;
  max-width: 50%; /* Ensure the image doesn't exceed its container */
  align-self: center; /* Center the image vertically within its flex container */
}

.grid-item .grid_inner_container {
  padding: 1rem;
}

.grid-item{
    border-radius: 1rem;
}

.about_inner_left_container h2{
    white-space: pre-line;
    word-break: break-all;
    text-align: left;
    font-size: 2rem;
    padding-left: 15rem;
}