.business-page {
    background-image: url('../../images/business.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .body{
    padding: 0 2rem;
  }

  .business_buttons{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.business_button{
    color: #fff !important;
    background-color: #9e430796 !important;
    /* border-color:#9e4307 !important; */
    border: none !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    
}