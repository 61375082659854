.business-item-container{
    margin: 0 6rem;
}

.business-item-inner-container{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.left-service-business-item,
.right-service-business-item {
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 20px;
  box-sizing: border-box;
}

h2{
    margin-top: 2rem;
}

.business-item-image{
    margin-top: 5rem;
}