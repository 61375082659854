.business-media {
    background-image: url('../../images/test2.webp');
    /* height: 100%; */
    max-width: 100vw;
    background-size: cover;
    background-position: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    color: #fff;
  }

  .business-media-body{
    padding: 0 1rem;
    display: flex;
  }

  .business-media-inner{
    padding-top: 2rem;
    width: 45vw;
    /* background-color: #9e430762 ; */
    /* color: #271305; */
    font-weight: 500;
  }