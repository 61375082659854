footer {
    width: 100%;
    height: 26vh;
    margin: 0;
    font: 200 0.875rem 'Oswald', sans-serif;
    color: #fff; 
    letter-spacing: 0.1rem;
    position: relative;
    text-align: left;
    bottom: 0;
}

.footer-container{
  display: flex;
  margin: 0 6rem;
}

.footer-left-container{
  flex: 2;
  padding: 1rem; 
}

.footer-right-container {
  flex: 1;
  padding: 1rem; 
}

.footer-right-container{
  float: right;
}

.footer-left-container p{
  font-size: 0.7rem;
  margin-top: 0.5rem;
}

.footer-left-container h3{
  margin: 0;
}

.footer-left-container .social-media-links {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
}

.footer-left-container .social-media-links .icon {
  margin: 0 0.2rem;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.footer-right-container ul{
  padding: 0;
}

.footer-right-container li{
  list-style: none;
}

.footer-right-container p, .footer-right-container h4{
  margin: 0;
}


.footer-right-container p{
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

footer a {
  color: #fff;
  padding-bottom: 0;
}
    
.footer-icon{
  font-size: 1.8rem;
  padding-right: 0.6rem;
  padding-top: 0.8rem;
  color: #fff;
}

.footer-basket{
  padding-right: 0;
}


footer .copyright{
  font-size: 0.8rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

